import React from "react"
import {
  Helmet,
  Theme,
  Banner,
  Row,
  Container,
  Box,
  Section,
  Heading,
  Interfaces,
  IconTick,
  IconPhone,
  IconAt,
  TelLink,
  KeyCodes,
  Panel,
  TickListItem,
  routesObject,
  PreStyledComponents
} from "@life-without-barriers/react-components"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Site } from "@life-without-barriers/gatsby-common"
import { ThemeProps, withTheme } from "styled-components"
import {
  contentfulHelpers as Contentful,
  scroll
} from "@life-without-barriers/utilities"
import { SRLWrapper, useLightbox } from "simple-react-lightbox"

import { STAListing } from "../../contentTypes"
import Layout from "../../components/disability/Layout"
import { DisabilityForm } from "@life-without-barriers/shared-contact-form"
import {
  ThumbnailOverlay,
  FeatureOverlay
} from "../../components/disability/ReusedComponents"
import classNames from "classnames"

const { disabilityTheme } = Theme

const { IconWrapper, StandardButton } = PreStyledComponents

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    currentPage: STAListing & { openGraphImage: Contentful.RemoteImageAsset }
    openGraphImage: {
      childImageSharp: IGatsbyImageData
    }
  }
}

const AccommodationType = withTheme(
  ({
    theme,
    title,
    description
  }: {
    title: string
    description: string
  } & ThemeProps<Interfaces.Theme>) => (
    <li key={title} className="pt4">
      <div className="flex">
        <IconWrapper
          role="presentation"
          className="IconWrapper mr3"
          size="30"
          color={theme.light}
        >
          <IconTick aria-hidden height="13" color="white" />
        </IconWrapper>
        <div>
          <Heading size={3} topMargin="none">
            {title}
          </Heading>
          <p>{description}</p>
        </div>
      </div>
    </li>
  )
)

const FeatureBox = withTheme(
  ({
    theme,
    className,
    title,
    items
  }: {
    className: string
    title: string
    items: string[]
  } & ThemeProps<Interfaces.Theme>) => (
    <Box className={className}>
      <Panel background="bg-lwb-theme-xxx-light">
        <Heading className="heading3" size={2} topMargin="none">
          {title}
        </Heading>
        <ul className="pl0 list">
          {items.map((item, i) => (
            <TickListItem
              key={i}
              className={i === 0 ? "mt4" : "mt3"}
              text={item}
              theme={theme}
            />
          ))}
        </ul>
      </Panel>
    </Box>
  )
)

const EnquireNowButton = () => (
  <div className="w-100 db dn-l center tc mt4">
    <StandardButton
      className="w-100 center"
      shade="xdark"
      onClick={() => scroll.scrollToElement("get-in-touch")}
    >
      Enquire Now
    </StandardButton>
  </div>
)

const StaListingTemplate = ({
  location: { pathname },
  data: {
    site: { siteMetadata },
    currentPage: {
      title,
      region,
      state,
      displayEmail,
      displayPhoneNumber,
      listingDescription,
      openGraphImage,
      thingsToConsiderWhenStayingWithUs,
      listingIntroductionText,
      featureImage,
      galleryImages,
      ageSuitability,
      accessibility,
      lengthOfStay,
      propertyFeatures,
      ourSupportModel,
      nearbyAttractions,
      nearbyAttractionsImage
    }
  }
}: Props) => {
  const breadcrumbItems = [
    routesObject.disabilityHome,
    {
      to: "/disability/services",
      text: "Disability services",
      title: "Return navigation"
    },
    {
      to: "/disability/services/short-term-accommodation",
      text: {
        long: "Short Term Accommodation (Respite)",
        short: "STA (Respite)"
      },
      title: "Return navigation"
    }
  ]

  const allImages =
    (galleryImages &&
      galleryImages.map(({ description, gatsbyImageData }) => ({
        description,
        gatsbyImageData
      }))) ||
    []

  allImages.unshift({
    description: featureImage.description,
    gatsbyImageData: featureImage.gatsbyImageData
  })

  const topThreeImages = allImages.slice(1, 4)

  const galleryData = allImages.map((allImagesData) => {
    return {
      src: allImagesData.gatsbyImageData.images.fallback?.src || "",
      width: allImagesData.gatsbyImageData.width,
      height: allImagesData.gatsbyImageData.height,
      caption: allImagesData.description || ""
    }
  })

  const galleryOptions = {
    caption: {
      showCaption: false,
      overlayColor: "rgba(0,0,0,0.8)"
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showThumbnailsButton: false
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent, id: number) => {
    if (e.keyCode !== KeyCodes.SPACE && e.keyCode !== KeyCodes.RETURN) {
      return false
    }
    openLightbox(id)
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { openLightbox } = useLightbox()

  return (
    <div>
      <Helmet
        title={`${title} STA Respite | ${siteMetadata.title}`}
        description={listingDescription}
        image={openGraphImage.gatsbyImageData}
        siteUrl={siteMetadata.siteUrl}
        path={pathname}
        themeColor={disabilityTheme.medium}
      />
      <Layout
        fixedCTAPhoneNumber="1800 610 699"
        fixedCTAContact={
          <DisabilityForm
            formTitle="Enquire now"
            formId="disability-sta-listing"
            formKind="disability-home-living"
            hideNdisField={false}
          />
        }
      >
        <Banner
          title={`${title} STA`}
          subTitle={`Short term accommodation (Respite) in ${region}, ${state}`}
          centered
          breadcrumbs={breadcrumbItems}
          share={true}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <p className="mt0 mb3 color-lwb-theme-dark w-100 ts-display-4 fw8">
                {listingDescription}
              </p>
            </Box>
          </Row>
          <Row className="flex-column flex-column-reverse-l">
            <Box>
              <div className="flex flex-wrap">
                <div
                  className="w-100 w-75-ns relative pointer"
                  role="button"
                  onClick={() => openLightbox(0)}
                  onKeyUp={(e) => handleKeyDown(e, 0)}
                  tabIndex={0}
                  aria-label="Open gallery"
                >
                  <GatsbyImage
                    image={allImages[0].gatsbyImageData}
                    alt={allImages[0].description}
                  />
                  <FeatureOverlay className="flex dn-ns white ph3 pv2">
                    1/{allImages.length}
                  </FeatureOverlay>
                </div>
                <div className="dn db-ns w-25 pl2-ns flex-ns flex-column-ns">
                  {topThreeImages.map((image, i) => (
                    <div
                      key={i}
                      className={classNames("w-100 pointer relative", {
                        mb2: i <= 1
                      })}
                      role="button"
                      onClick={() => openLightbox(i + 1)}
                      onKeyUp={(e) => handleKeyDown(e, i + 1)}
                      tabIndex={0}
                      aria-label="Open gallery"
                    >
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        alt={image.description}
                      />
                      <ThumbnailOverlay
                        className={`white ts-display-2 ${
                          i === 2 && allImages.length >= 5 ? `flex` : `dn`
                        } `}
                      >
                        +{allImages.length - 4}
                      </ThumbnailOverlay>
                    </div>
                  ))}
                </div>
              </div>
              <SRLWrapper elements={galleryData} options={galleryOptions} />
            </Box>
            <Box className="pb0 pb4-l">
              {renderRichText(listingIntroductionText)}
            </Box>
          </Row>
          <EnquireNowButton />
          <Row className="flex-wrap" topMargin="standard">
            <FeatureBox
              className="w-100 w-50-m w-third-ns"
              title="Age suitability"
              items={ageSuitability}
            />
            <FeatureBox
              className="mt3 mt0-ns w-100 w-50-m w-third-ns"
              title="Length of stay"
              items={lengthOfStay}
            />
            <FeatureBox
              className="mt3 mt0-l w-100 w-50-m w-third-ns"
              title="Accessibility"
              items={accessibility}
            />
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box>
                <Heading size={2}>What is included in your stay</Heading>
                <p className="layout-readable">
                  Supports are tailored to your individual needs and delivered
                  by qualified and friendly staff in a safe and supportive
                  environment where you can learn new skills and build your
                  independence.
                </p>
              </Box>
              <Box>
                <ul className="pl0 list layout-columns">
                  <AccommodationType
                    title="24/7 specialised support"
                    description="Specialised staff available 24/7 providing support during overnight, short and extended stays."
                  />
                  <AccommodationType
                    title="Nutritious meals"
                    description="Balanced and delicious meals including breakfast, morning tea, lunch, afternoon tea and dinner."
                  />
                  <AccommodationType
                    title="Fully accessible"
                    description="An accessible environment that provides all the support you will need."
                  />
                  <AccommodationType
                    title="Activities and community access"
                    description="Enjoy social activities, excursions, games, movies, cooking, sports and more. We also run themed weekends and special events."
                  />
                </ul>
              </Box>
            </Row>
            <EnquireNowButton />
          </Container>
        </Section>
        <Container>
          <Row>
            <Box className="w-100">
              <Heading
                size={2}
              >{`About ${title} short term accommodation`}</Heading>
              <Row className="flex-wrap">
                <Box className="w-100 w-50-l layout-gap">
                  <Heading size={3}>Property features</Heading>
                  {renderRichText(propertyFeatures)}
                  <Heading size={3}>Our support model</Heading>
                  {renderRichText(ourSupportModel)}
                </Box>
                <Box className="w-100 w-five-twelfths-l mt4_5-ns mt4 mt0-ns">
                  <Panel height="initial" background="bg-lwb-grey-xxx-light">
                    <GatsbyImage
                      image={nearbyAttractionsImage.gatsbyImageData}
                      alt={nearbyAttractionsImage.description}
                    />
                    <Heading size={3} topMargin="standard">
                      Nearby attractions
                    </Heading>
                    {renderRichText(nearbyAttractions)}
                  </Panel>
                </Box>
              </Row>
            </Box>
          </Row>
          <EnquireNowButton />
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>I’m interested – what do I do now?</Heading>
                <Heading size={3}>
                  Things to consider when staying with us
                </Heading>
                {renderRichText(thingsToConsiderWhenStayingWithUs)}
                <div id="get-in-touch">
                  <Heading size={3}>Get in touch with our team today</Heading>
                </div>
                <p>
                  Get in touch with the Life Without Barriers team today if you
                  are looking for supported accommodation for your next holiday
                  or respite stay. Our friendly team are ready to answer your
                  questions about STA!
                </p>
                <Row className="mt3 mt4_5-l flex-wrap">
                  <Box className="w-100 w-50-ns">
                    <div className="mt2 flex items-center">
                      <IconWrapper size="30" color="#FFF">
                        <IconPhone
                          height="20"
                          width="20"
                          color={disabilityTheme.dark}
                        />
                      </IconWrapper>
                      <div className="pl2">
                        <TelLink
                          className="black no-underline f3 fw8"
                          phoneNumber={displayPhoneNumber}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box className="w-100 w-50-ns">
                    <div className="mt2 flex items-center">
                      <IconWrapper size="30" color="#FFF">
                        <IconAt
                          height="20"
                          width="20"
                          color={disabilityTheme.dark}
                        />
                      </IconWrapper>
                      <div className="pl3">
                        <a
                          className="black fw4"
                          href={`mailto:${displayEmail}`}
                        >
                          {displayEmail}
                        </a>
                      </div>
                    </div>
                  </Box>
                </Row>
              </Box>
              <Box className="w-100 w-third-l mt4 mt0-l">
                <DisabilityForm
                  formTitle="Enquire now"
                  formId="disability-sta-listing"
                  formKind="disability-home-living"
                  hideNdisField={false}
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String) {
    currentPage: contentfulStaListing(id: { eq: $id }) {
      id
      title
      region
      state
      listingDescription
      displayEmail
      displayPhoneNumber
      openGraphImage: featureImage {
        ...OpenGraphImageAssetQuery
      }
      thingsToConsiderWhenStayingWithUs {
        raw
      }
      listingIntroductionText {
        raw
      }
      openGraphImage: featureImage {
        ...OpenGraphImageAssetQuery
      }
      featureImage {
        ...ForcedAspectRatioRemoteImageAssetQuery
      }
      galleryImages {
        ...ForcedAspectRatioRemoteImageAssetQuery
      }
      accessibility
      ageSuitability
      lengthOfStay
      propertyFeatures {
        raw
      }
      ourSupportModel {
        raw
      }
      nearbyAttractions {
        raw
      }
      nearbyAttractionsImage {
        ...ForcedAspectRatioRemoteImageAssetQuery
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default StaListingTemplate
